<template>
  <v-row>
    <!-- Filters -->
    <v-col cols="12">
      <v-card>
        <v-card-text class="pb-0">
          <v-row class="ps-2">
            <v-col cols="4">
              <v-select
                v-model="tabs"
                :items="items"
                dense
                outlined
                hide-details
                label="Nhóm doanh nghiệp"
              />
            </v-col>
            <v-col cols="2">
              <v-select
                v-model="delay"
                :items="terms"
                dense
                outlined
                hide-details
                label="Kỳ hiện tại"
              />
            </v-col>
            <v-col cols="4">
              <v-btn
                color="primary"
                @click="filter"
              >
                Lọc dữ liệu
              </v-btn>
            </v-col>
          </v-row>
        </v-card-text>

        <v-tabs-items v-model="tabs">
          <v-tab-item
            v-for="group in filterGroups"
            :key="group.id"
          >
            <v-card
              flat
              class="pa-3 mt-2"
            >
              <v-card-text>
                <v-row>
                  <v-col
                    v-for="f in group.filters"
                    :key="f.id"
                    cols="12"
                  >
                    <CompanyFilter
                      :label="f.label"
                      :operation="f.operation"
                      :value="f.value"
                      :step="f.step"
                      @operation="f.operation = $event"
                      @value="f.value = $event"
                    />
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-tab-item>
        </v-tabs-items>
      </v-card>
    </v-col>

    <!-- Result -->
    <v-col cols="12">
      <v-card>
        <v-card-text class="pa-5">
          <v-row>
            <v-col
              cols="12"
              md="2"
            >
              <v-text-field
                v-model="symbol"
                label="Mã CK"
                dense
                outlined
                solo
                flat
                hide-details
              />
            </v-col>
            <v-col
              cols="12"
              md="4"
            >
              <v-autocomplete
                v-model="exchanges"
                :items="exchangeList"
                label="Sàn"
                append-icon=""
                chips
                small-chips
                deletable-chips
                dense
                hide-details
                multiple
                outlined
                single-line
              />
            </v-col>
            <v-col
              cols="12"
              md="6"
            >
              <v-autocomplete
                v-model="industries"
                :items="industryList"
                label="Ngành"
                append-icon=""
                chips
                small-chips
                deletable-chips
                dense
                hide-details
                multiple
                outlined
                single-line
              />
            </v-col>
          </v-row>
        </v-card-text>

        <v-divider v-if="!hasData"></v-divider>

        <v-data-table
          :hide-default-header="!hasData"
          :headers="headers"
          :items="filteredCompanies || []"
          :loading="loading"
          :footer-props="{
            'items-per-page-options': [10, 20, 30, 40, 50]
          }"
          sort-by="symbol"
        >
          <template #item.symbol="{ item }">
            <router-link
              :to="{ name: 'chart', query: { symbol: item.symbol } }"
              class="font-weight-semibold text-decoration-none"
              target="_blank"
            >
              <v-icon
                dense
                class="mr-1"
              >
                mdi-chart-box-outline
              </v-icon>
            </router-link>
            <router-link
              :to="{ name: 'company-summary', params: { symbol: item.symbol } }"
              class="font-weight-semibold text-decoration-none"
              target="_blank"
            >
              {{ item.symbol }}
            </router-link>
          </template>
        </v-data-table>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>

import { mapGetters } from 'vuex'
import TimeUtils from '@/common/utils/time'
import industryList from '../components/definitions/industries.json'
import filterGroups from '../components/definitions/filters.json'

export default {

  components: {
    CompanyFilter: () => import('../components/Filter.vue'),
  },

  data() {
    return {
      exchangeList: ['HNX', 'HOSE', 'UPCoM'],
      industryList,
      filterGroups,
      loading: false,
      exchanges: [],
      industries: [],
      symbol: '',
      delay: 1,
      delayOptionNum: 16,
      headersFixed: [
        {
          text: 'Mã CK',
          align: 'start',
          value: 'symbol',
          width: '100px',
          filter: value => {
            if (this.symbol === '') return true

            return value.includes(this.symbol.toUpperCase())
          },
        },
        {
          text: 'Sàn',
          value: 'exchange',
          width: '100px',
          filter: value => {
            if (this.exchanges.length === 0) return true

            return this.exchanges.includes(value)
          },
        },
        {
          text: 'Ngành',
          value: 'industry',
          filter: value => {
            if (this.industries.length === 0) return true

            return this.industries.includes(value)
          },
        },
      ],
      headersFilter: [],
      tabs: 0,
      filteredData: null,
    }
  },

  computed: {
    ...mapGetters('company', ['companies']),
    items() {
      const result = []

      Object.keys(this.filterGroups).forEach(key => {
        const value = this.filterGroups[key]
        result.push({
          text: value.title,
          value: value.id,
        })
      })

      return result
    },

    terms() {
      const result = []
      for (let i = 1; i <= this.delayOptionNum; i += 1) {
        result.push({
          text: TimeUtils.getQuarter(i),
          value: i,
        })
      }

      return result
    },

    filteredCompanies() {
      if (!this.companies || !this.filteredData) {
        return []
      }

      let result = []
      this.companies.forEach(company => {
        if (this.filteredData[company.symbol]) {
          const data = this.filteredData[company.symbol]
          company = { ...company, ...data }
          result = [...result, company]
        }
      })

      return result
    },

    hasData() {
      return this.filteredCompanies.length > 0
    },

    headers() {
      return [...this.headersFixed, ...this.headersFilter]
    },
  },

  methods: {
    filter() {
      this.loading = true
      const payload = {
        delay: this.delay,
        filters: this.filterGroups[this.tabs].filters,
      }
      this.$http.post('/api/companies/search', payload)
        .then(response => {
          this.filteredData = response.data.data
          this.updateFilterHeader()
        })
        .catch(() => {
          this.filteredData = []
        })
        .finally(() => {
          this.loading = false
        })
    },

    updateFilterHeader() {
      let headers = []
      const { filters } = this.filterGroups[this.tabs]
      filters.forEach(filter => {
        headers = [...headers, {
          align: 'center',
          text: filter.label,
          value: filter.id.toString(),
        }]
      })
      this.headersFilter = headers
    },
  },
}

</script>
